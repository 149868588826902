import { Session } from './Session'

export class RequestError extends Error {
  statusCode: number
  errorCode: string
  url: string
  cid: string
  readonly type = 'error' as const
  meta: Record<string, unknown>

  constructor({
    statusCode,
    errorCode,
    url,
    message,
    cid,
    meta,
  }: {
    statusCode: number
    errorCode: string
    url?: string
    message?: string
    cid?: string
    meta?: Record<string, unknown>
  }) {
    super(
      `Request${url ? ` ${url}` : ''} failed with ${statusCode} ${errorCode}${
        message ? `: ${message}` : ''
      }${cid ? ` (server cid: ${cid})` : ''}`
    )
    this.statusCode = statusCode
    this.errorCode = errorCode
    this.url = url ?? ''
    this.cid = cid ?? ''
    this.meta = meta ?? {}
  }
}

export class SessionExpiredError extends RequestError {
  session: Session

  constructor({ session, url }: { session: Session; url?: string }) {
    super({
      statusCode: 400,
      errorCode: 'SESSION_EXPIRED',
      url,
      message: `Session expired: ${session.sessionId}`,
    })
    this.session = session
  }
}

export class NotFoundError extends RequestError {
  constructor({
    errorCode = 'NOT_FOUND',
    url,
    message,
  }: {
    errorCode?: string
    url?: string
    message?: string
  }) {
    super({ statusCode: 404, errorCode, url, message })
  }
}

export class SessionRequestError extends RequestError {
  constructor() {
    super({ statusCode: 400, errorCode: 'SESSION_REQUEST_ERROR' })
  }
}

export const errorJson = (error: unknown) => {
  if (error instanceof RequestError) {
    return {
      code: error.errorCode,
      message: error.message,
    }
  } else if (error instanceof Error) {
    return {
      code: `${error}`,
      message: error.message,
    }
  }
  return {
    code: 'UNKNOWN',
    message: `${error}`,
  }
}
