import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import React from 'react'

export type Props = {
  error?: string
  label?: string
  touched?: boolean
  disabled?: boolean
  subLabel?: React.ReactNode
  forwardRef?: React.Ref<HTMLInputElement>
} & React.InputHTMLAttributes<HTMLInputElement>

const Input = ({ error, subLabel, label, touched, disabled, forwardRef, ...props }: Props) => {
  const errorEl = error ? <span className="text-red-400">{error}</span> : null
  const subLabelEl = subLabel && !error ? <span className="opacity-75">{subLabel}</span> : null
  return (
    <div className="flex flex-col flex-grow">
      {label ? <label className="font-medium">{label}</label> : null}
      <div
        className={`w-full mt-2 mb-2 flex flex-row bg-white rounded-md ring-1 focus-within:ring-1 ${
          error ? 'ring-red-400' : 'ring-gray-300'
        }`}>
        <input
          className={`flex-grow w-40 p-3 rounded-md border-0 focus:ring-0 ${
            disabled ? ' text-gray-500' : ''
          }`}
          disabled={disabled}
          ref={forwardRef}
          {...props}
        />
        {error ? <ExclamationCircleIcon className="h-6 m-3 text-red-400" /> : null}
        {touched && !error ? <CheckCircleIcon className="h-6 m-3 text-green-400" /> : null}
      </div>
      <p className="h-4 font-normal text-xs">
        {errorEl}
        {subLabelEl}
      </p>
    </div>
  )
}

export default Input
