import { createContext, PropsWithChildren } from 'react'

import { UserProfileData } from '../../api-utils/service-requests/user-sessions'

export type UserProfileContext = UserProfileData | null

export const UserProfileContext = createContext<UserProfileContext>(null)

type Props = PropsWithChildren<{
  userProfile: UserProfileData | null
}>

export const UserProfileProvider = (props: Props) => {
  const { children, userProfile } = props

  return <UserProfileContext.Provider value={userProfile}>{children}</UserProfileContext.Provider>
}
