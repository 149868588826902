import { useEffect, useState } from 'react'

type Props = {
  contentRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
  shouldUseDynamicHeight: boolean
}

export function useElementChildrensHeight({ contentRef, children, shouldUseDynamicHeight }: Props) {
  const [wrapperHeight, setWrapperHeight] = useState('auto')

  useEffect(() => {
    if (!shouldUseDynamicHeight) return
    /**
     * Calculate the wrapper's total height based on its content and
     * ensure it dynamically adjusts when changes occur, allowing
     * for smooth height animation of the drawer.
     * For example, this functionality can be observed when items are removed from an open cart.
     */

    const calculateTotalHeight = () => {
      if (contentRef.current) {
        const totalHeight = Array.from(contentRef.current.children).reduce(
          (acc, child) => acc + (child as HTMLElement).clientHeight,
          0
        )
        return totalHeight
      }
      return 0
    }

    const updateHeight = () => {
      const totalHeight = calculateTotalHeight()
      setWrapperHeight(`${totalHeight}px`)
    }

    // Initially set the height
    updateHeight()

    // Recalculate and update the height whenever children change
    const observer = new MutationObserver(updateHeight)
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true })
    }

    return () => {
      observer.disconnect()
    }
  }, [contentRef, children, shouldUseDynamicHeight])

  return wrapperHeight
}
