import axios from 'axios'
import { useEffect } from 'react'

import { _T, useTranslations } from '../components/helpers'
import { KlarnaSDK } from '../models/klarna/types'

type ErrorMessage = {
  translated: boolean
  message: string
}

type Args = {
  onSuccess: () => void
  onError: (error: ErrorMessage) => void
  onStart?: () => void
  onReady?: () => void
  buttonSelector: string
}

export const useSignInWithKlarna = (args: Args) => {
  const { onSuccess, onError, onReady, onStart, buttonSelector } = args

  const tr = useTranslations(translations)

  useEffect(() => {
    const initializeKlarnaSDK = async (klarna: KlarnaSDK) => {
      klarna.Identity.on('error' as const, async (error) => {
        onError({ translated: false, message: `${error}` })
      })

      klarna.Identity.on('signin' as const, async (payload) => {
        try {
          const response = await axios.post(
            '/api/user-session/sign-in/klarna',
            { payload },
            { validateStatus: (status: number) => status === 201 || status === 400 }
          )
          if (response.status === 201) {
            onSuccess()
            return
          }
          if (response.status === 400) {
            const { data } = response
            if (data.code === 'EMAIL_NOT_VERIFIED') {
              onError({ translated: true, message: tr.errorEmailNotVerified })
              return
            }
          }
          throw new Error('Unexpected response')
        } catch (error) {
          if (error instanceof Error) {
            onError({ translated: false, message: error.message })
          } else {
            onError({ translated: false, message: `${error}` })
          }
        }
      })

      const scope = `openid offline_access payment:request:create profile:email profile:phone profile:name`
      const { location } = window
      const redirectUri = new URL('/sign-in', location.href).href
      const button = klarna.Identity.button({
        scope,
        redirectUri: redirectUri.toString(),
        locale: 'en-GB',
      })
      button.on('ready', () => {
        onReady?.()
      })
      button.on('click', () => {
        onStart?.()
      })
      button.attach(buttonSelector)
    }
    window.KlarnaSDKCallback = initializeKlarnaSDK
    return () => {
      window.KlarnaSDKCallback = undefined
    }
    // eslint-disable-next-line -- The Klarna button can't initialize more than once, so no deps
  }, [])
}

const translations = _T({
  en: {
    errorEmailNotVerified: `Your email is not verified. Please sign in to Klarna and verify your email, or use your email above to sign up with Karma.`,
  },
  sv: {
    errorEmailNotVerified: `Din e-post är inte verifierad. Vänligen logga in på Klarna och verifiera din e-post, eller använd din e-post ovan för att registrera dig med Karma.`,
  },
  no: {
    errorEmailNotVerified: `E-posten din er ikke verifisert. Vennligst logg inn på Klarna og verifiser e-posten din, eller bruk e-posten din over for å registrere deg med Karma.`,
  },
})
