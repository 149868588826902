import React from 'react'

import { Backdrop } from '../../components/backdrop'
import BottomDrawer from '../../components/bottom-drawer'
import { useSignInDrawer } from '../useSignInDrawer'
import { SignInContent, SignInContentProps } from './SignInContent'

type Props = {
  isOpen: boolean
} & SignInContentProps

export const SignInDrawer = (props: Props) => {
  const { isOpen, ...signInContentProps } = props
  const signInDrawer = useSignInDrawer()

  return (
    <>
      <Backdrop shouldShow={isOpen} onClickCallback={() => signInDrawer.setIsOpen(false)} />
      <BottomDrawer shouldUseDynamicHeight portalId="sign-in-drawer" isOpen={isOpen}>
        <SignInContent {...signInContentProps} />
      </BottomDrawer>
    </>
  )
}
