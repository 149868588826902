import axios from 'axios'
import Image from 'next/image'
import router from 'next/router'
import Script from 'next/script'
import React, { useEffect, useRef, useState } from 'react'

import { _T, useTranslations } from '../../components/helpers'
import { isValidEmail as validateEmail } from '../../components/helpers/validator'
import { InfoBox } from '../../components/info-box'
import { Button } from '../../ui/Button/Button'
import Input from '../../ui/Input/Input'
import { BodyText, Heading } from '../../ui/Typography'
import { getHeadersWithSentryTraceId } from '../../utils/metrics'
import { SignInConfigs } from '../../utils/page-types'
import { useSignInWithKlarna } from '../useSignInWithKlarna'

const SIGN_IN_WITH_KLARNA_BUTTON_ID = 'sign-in-with-klarna-button'
const HACK_TEXT_TO_SHOW_ERROR_STATE_BUT_NO_LABEL = ' '

export type SignInContentProps = {
  defaultEmail: string | null
  signInConfigs: SignInConfigs
}

type TitleBody = {
  title: string
  body: string
}

export const SignInContent = (props: SignInContentProps) => {
  const { defaultEmail, signInConfigs } = props
  const tr = useTranslations(translations)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState<TitleBody | null>(null)
  const [errorMessage, setErrorMessage] = useState<TitleBody | null>(null)
  const [email, setEmail] = useState(defaultEmail ?? '')
  const [isValidEmail, setIsInvalidEmail] = useState(email.length === 0 || validateEmail(email))
  const [isKlarnaLoaded, setIsKlarnaLoaded] = useState(false)

  useSignInWithKlarna({
    onSuccess: () => {
      setSuccessMessage({
        title: tr.successSignedInTitle,
        body: tr.successSignedInBody,
      })
      setErrorMessage(null)
      setIsLoading(true) // Prevent further interaction while waiting for redirect
      router.reload()
    },
    onError: (error) => {
      const { translated, message } = error
      setErrorMessage({
        title: tr.error,
        body: translated ? message : tr.thirdPartyError({ p: 'Klarna', e: message }),
      })
      setIsLoading(false)
    },
    onStart: () => {
      setIsLoading(true)
    },
    onReady: () => {
      setIsKlarnaLoaded(true)
    },
    buttonSelector: `#${SIGN_IN_WITH_KLARNA_BUTTON_ID}`,
  })

  useEffect(() => {
    emailRef.current?.focus()
    if (email.length > 0) {
      setIsInvalidEmail(validateEmail(email))
    }
  }, [emailRef, email])

  const postLoginRequest = async () => {
    if (!email) {
      return
    }
    const returnTo = getReturnToUrl()

    if (!returnTo) {
      throw new Error('Browser prevented access to url, which is required to sign in')
    }
    const body = { email, returnTo }
    const headers = { headers: getHeadersWithSentryTraceId({ 'Content-Type': 'application/json' }) }
    setIsLoading(true)
    setSuccessMessage(null)
    try {
      const response = await axios.post('/api/user-session/sign-in', body, headers)
      if (response.status !== 201) {
        const msg = `Unexpected response status (expected 201 got ${response.status}) during sign in`
        throw new Error(msg)
      }
      setSuccessMessage({
        title: tr.successLinkSentTitle,
        body: tr.successLinkSentBody,
      })
      setIsLoading(false)
      setErrorMessage(null)
    } catch (error) {
      setSuccessMessage(null)
      setIsLoading(false)
      setErrorMessage({
        title: tr.error,
        body: tr.linkSendError,
      })
    }
  }

  const isSuccess = successMessage !== null

  return (
    <div className="px-5 pt-8 pb-8 flex flex-col justify-center container max-w-2xl">
      <Heading level={3}>{tr.heading}</Heading>
      <BodyText level={2} className="mt-4 mb-6">
        {tr.description}
      </BodyText>
      <Input
        disabled={isLoading || isSuccess}
        type="text"
        value={email}
        forwardRef={emailRef}
        error={isValidEmail ? undefined : HACK_TEXT_TO_SHOW_ERROR_STATE_BUT_NO_LABEL}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
      />
      <Button
        type={'secondary'}
        className={`w-full mx-auto max-w-md`}
        disabled={isLoading || !isValidEmail || isSuccess}
        onClick={postLoginRequest}>
        {tr.continue}
      </Button>
      {signInConfigs ? (
        <div>
          <div className="relative flex items-center py-3 max-w-[150px] mx-auto">
            <div className="flex-grow border-t border-[#8a8a8a]"></div>
            <span className="flex-shrink mx-4 text-[#8a8a8a] text-sm">{tr.or}</span>
            <div className="flex-grow border-t border-[#8a8a8a]"></div>
          </div>
          {signInConfigs.klarnaClientId && (
            <>
              <Script
                defer
                src="https://js.klarna.com/web-sdk/v1/klarna.js"
                data-client-id={signInConfigs.klarnaClientId}
              />
              {/*
                NOTE(christoffer): We can't change the settings fo the Sign in with Klarna button after the Klarna
                SDK has attached to it (guessing the SDK adds some props which makes it loose connection in the virtual
                dom or something?). Se we fake disabling the button by adding an overlay div on top of it.
                We should probably add an onClick listener which also prevents the propagation of events from the button
                so that it's actually disabled, but I haven't managed to get that to work.
              */}
              <div className={!isKlarnaLoaded || isSuccess ? 'opacity-40' : ''}>
                <Button
                  id={SIGN_IN_WITH_KLARNA_BUTTON_ID}
                  type={'secondary'}
                  className={`w-full mx-auto max-w-md`}>
                  {tr.signInWithKlarna}
                  <Image
                    src="/ui-icons/klarna.png"
                    alt="Klarna"
                    width={20}
                    height={20}
                    className={'ml-3'}
                  />
                </Button>
              </div>
            </>
          )}
        </div>
      ) : null}
      {errorMessage && (
        <InfoBox
          title={errorMessage.title}
          body={errorMessage.body}
          type={'error'}
          className={'mt-4'}
        />
      )}
      {successMessage && (
        <InfoBox
          title={successMessage.title}
          body={successMessage.body}
          type={'success'}
          className={'my-4'}
        />
      )}
    </div>
  )
}

const getReturnToUrl = (): string | null => {
  if (!window.location) {
    throw new Error('Failed to read window.location')
  }

  const currentUrl = new URL(window.location.href)
  const returnTo = currentUrl.searchParams.get('returnTo')

  const { protocol, hostname, port } = window.location

  const url = new URL(protocol + '//' + hostname + (port ? `:${port}` : ''))
  url.pathname = '/sign-in/confirm'

  if (returnTo) {
    url.searchParams.set('returnTo', returnTo)
  }

  return url.toString()
}

const translations = _T({
  en: {
    heading: 'Welcome',
    description: `Use your email to sign in or join. We'll send you a secure link — no password needed!`,
    continue: 'Continue',
    errorInvalidEmail: 'Invalid email',
    successLinkSentTitle: `Link sent!`,
    successLinkSentBody:
      'A link has been sent to your email, open your email app and click the link to continue',
    error: 'Error signin in',
    linkSendError: 'There was an error sending the sign in link',
    thirdPartyError: (args: { p: string; e: string }) =>
      `There was an error signing in with ${args.p}: ${args.e}`,
    or: 'or',
    signInWithKlarna: 'Sign in with Klarna',
    successSignedInTitle: `Welcome!`,
    successSignedInBody: `You're now signed in. Loading...`,
  },
  sv: {
    heading: 'Välkommen',
    description: `Använd din e-postadress för att logga in eller gå med. Vi skickar dig en säker länk — inget lösenord behövs!`,
    continue: 'Fortsätt',
    errorInvalidEmail: 'Ogiltig e-post',
    successLinkSentTitle: `Länk skickad!`,
    successLinkSentBody:
      'En länk har skickats till din e-post, öppna din e-postapp och klicka på länken för att fortsätta',
    error: 'Fel vid inloggning',
    linkSendError: 'Ett fel uppstod vid sändning av länken',
    thirdPartyError: (args: { p: string; e: string }) =>
      `Det uppstod ett fel vid inloggning med ${args.p}: ${args.e}`,
    or: 'eller',
    signInWithKlarna: 'Logga in med Klarna',
    successSignedInTitle: `Välkommen!`,
    successSignedInBody: `Du är nu inloggad. Laddar...`,
  },
  no: {
    heading: 'Velkommen',
    description: `Bruk e-postadressen din for å logge inn eller bli med. Vi sender deg en sikker lenke — ingen passord nødvendig!`,
    continue: 'Fortsett',
    errorInvalidEmail: 'Ugyldig e-post',
    successLinkSentTitle: `Lenke send!`,
    successLinkSentBody:
      'En lenke har blitt sendt til e-posten din, åpne e-postappen din og klikk på lenken for å fortsette',
    error: 'Feil ved innlogging',
    linkSendError: 'Det oppstod en feil under sending av lenken',
    thirdPartyError: (args: { p: string; e: string }) =>
      `Det oppstod en feil under innlogging med ${args.p}: ${args.e}`,
    or: 'eller',
    signInWithKlarna: 'Logg inn med Klarna',
    successSignedInTitle: `Velkommen!`,
    successSignedInBody: `Du er nå logget inn. Laster...`,
  },
})
