type Props = {
  title: string
  body: string
  type: 'error' | 'success'
  className?: string
}

const ErrorBgColor = 'rgba(255, 0, 0, 0.1)'
const SuccessBgColor = 'rgba(0, 255, 0, 0.1)'

export const InfoBox = (props: Props) => {
  const { title, body, type, className = '' } = props
  const bgColor = type === 'error' ? ErrorBgColor : SuccessBgColor
  return (
    <div
      className={`rounded-lg p-4 ${className}`}
      style={{
        backgroundColor: bgColor,
      }}>
      <div className="font-bold text-sm">{title}</div>
      <div className="font-medium text-sm mt-1">{body}</div>
    </div>
  )
}
