import { createContext, PropsWithChildren, useState } from 'react'

import { SignInConfigs } from '../../utils/page-types'
import { SignInDrawer } from './SignInDrawer'

export type SignInContext = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const SignInContext = createContext<SignInContext>({
  isOpen: false,
  setIsOpen: () => ({}),
})

type Props = PropsWithChildren<{
  defaultEmail: string | null
  signInConfigs: SignInConfigs
}>

export const SignInProvider = (props: Props) => {
  const { children, defaultEmail, signInConfigs } = props
  const [isOpen, _setIsOpen] = useState(false)

  const setIsOpen = (isOpen: boolean) => {
    _setIsOpen(isOpen)
  }

  return (
    <SignInContext.Provider value={{ isOpen, setIsOpen }}>
      <SignInDrawer isOpen={isOpen} defaultEmail={defaultEmail} signInConfigs={signInConfigs} />
      {children}
    </SignInContext.Provider>
  )
}
