import '../css/styles.css'
import '../css/tailwind.css'
import '@adyen/adyen-web/dist/adyen.css'
import '../css/adyen-custom.css'
import '@fortawesome/fontawesome-svg-core/styles.css'

// Adding this so fontawsome icon style properly
// For documentation https://fontawesome.com/v5/docs/web/use-with/react#getting-font-awesome-css-to-work
import { config } from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/nextjs'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { makeLocale } from '../components/helpers'
import { clearEmptyStringTranslationCookie } from '../components/language-selector/utils'
import { PageTransition } from '../components/page-wrappers/page-transition'
import { SearchContext, SearchContextType } from '../hooks/useSearchContext'
import { SignInProvider } from '../hooks/useSignIn/context'
import { UserProfileProvider } from '../hooks/useUserProfile/context'
import polyfillIntlForLocale from '../utils/intl-polyfills'
import { AppPageProps } from '../utils/page-types'

config.autoAddCss = false

function App({ Component, pageProps: appPageProps }: AppProps<AppPageProps>) {
  // NOTE(martin 2024-08-15) When the translation feature was built, selecting 'No translation' would save a cookie with an empty string.
  // The backend expects the value from the cookie to be either a language code or undefined.
  // This function clears the cookie if it's an empty string, to prevent a validation error on the backend.
  // At the time of writing, the lifetime of this cookie is one year which means we can remove this code after 2025-08-15.
  clearEmptyStringTranslationCookie()

  const [searchVisible, setSearchVisible] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)

  const value: SearchContextType = {
    isVisible: searchVisible,
    isPreferenceFilterVisible: filterVisible,
    toggleSearch: () => {
      setSearchVisible(!searchVisible)
    },
    togglePreferenceDrawer: () => {
      setFilterVisible(!filterVisible)
    },
  }

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const [loadingPolyfill, setLoadingPolyfill] = useState(true)
  useEffect(() => {
    if (loadingPolyfill) {
      polyfillIntlForLocale(locale)
        .then(() => {
          setLoadingPolyfill(false)
        })
        .catch((error) => {
          console.log({ error })
          Sentry.captureException(error, { extra: { source: 'polyfillForLocal', locale } })
        })
    }
  }, [locale, setLoadingPolyfill, loadingPolyfill])
  if (loadingPolyfill) {
    // Note(marcus) Could perhaps show a loading here but it will probably just flash really quickly
    return <div />
  }
  // NOTE(christoffer): `{ appProps: { ... } }` comes from using withAppPageProps() to wrap getServerSideProps in individual pages.
  const { appProps, ...pageProps } = appPageProps
  const { defaultEmail, signInConfigs, userProfile } = appProps || {}
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      </Head>
      <UserProfileProvider userProfile={userProfile}>
        <SignInProvider defaultEmail={defaultEmail} signInConfigs={signInConfigs}>
          <SearchContext.Provider value={value}>
            <PageTransition>
              <Component {...pageProps} />
            </PageTransition>
          </SearchContext.Provider>
        </SignInProvider>
      </UserProfileProvider>
    </>
  )
}

export default App
