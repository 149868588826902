import { parsePhoneNumber } from 'awesome-phonenumber'
import isEmail from 'validator/lib/isEmail'

import { CountryCode } from '../../utils/country-codes'

export function getPhoneNumberInE164(phoneNumberString: string, countryCode: CountryCode) {
  if (phoneNumberString.charAt(0) !== '+') {
    return parsePhoneNumber(phoneNumberString, countryCode).getNumber('e164')
  }

  return parsePhoneNumber(phoneNumberString).getNumber('e164')
}

export function getPhoneNumberPrefix(phoneNumberString: string) {
  if (phoneNumberString.charAt(0) !== '+') {
    return null
  }
  const countryCode = parsePhoneNumber(phoneNumberString).getCountryCode()
  if (countryCode === 0) {
    return null
  }
  return `+${countryCode}`
}

export function isValidPhoneNumber(phoneNumberString: string, countryCode: CountryCode) {
  if (phoneNumberString.charAt(0) === '+') {
    const pn = parsePhoneNumber(phoneNumberString)
    return pn.isValid()
  }

  return parsePhoneNumber(phoneNumberString, countryCode).isValid()
}

export const isValidEmail = (emailString: string) => {
  return isEmail(emailString)
}
