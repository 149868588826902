import React, { useContext } from 'react'

export interface SearchContextType {
  isVisible: boolean
  isPreferenceFilterVisible: boolean
  toggleSearch: () => void
  togglePreferenceDrawer: () => void
}

export const SearchContext = React.createContext<SearchContextType>({
  isVisible: false,
  isPreferenceFilterVisible: false,
  toggleSearch: () => null,
  togglePreferenceDrawer: () => null,
})

export const useSearch = () => {
  const context = useContext(SearchContext)

  return {
    isVisible: context.isVisible,
    isPreferenceFilterVisible: context.isPreferenceFilterVisible,
    toggleSearch: context.toggleSearch,
    togglePreferenceDrawer: context.togglePreferenceDrawer,
  }
}
