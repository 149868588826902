import { useContext } from 'react'

import { SignInContext } from './useSignIn/context'

export const useSignInDrawer = () => {
  const signInContext = useContext(SignInContext)
  return {
    setIsOpen: (isOpen: boolean) => {
      signInContext.setIsOpen(isOpen)
    },
  }
}
