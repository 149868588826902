import { AxiosResponse } from 'axios'
import { IncomingMessage } from 'http'
import * as t from 'io-ts'
import reporter from 'io-ts-reporters'
import { UUID } from 'io-ts-types/lib/UUID'
import * as jwt from 'jsonwebtoken'
import { GetServerSidePropsContext, NextApiRequest } from 'next'
import { useRouter } from 'next/router'
import nookies, { parseCookies } from 'nookies'
import { TLSSocket } from 'tls'
import { v4 } from 'uuid'

import {
  GetSessionfulPageProps,
  GetSessionlessPageProps,
} from '../../components/page-wrappers/types'
import { EMAIL_COOKIE_PATH } from '../../utils/constants'
import { AppPageProps } from '../../utils/page-types'
import { Config, setupConfig } from '../config'
import { NotFoundError, RequestError, SessionExpiredError, SessionRequestError } from '../errors'
import { getLocationSlug } from '../get-location-slug'
import { logger } from '../logger'
import { Session } from '../Session'
import { getUserProfile, UserProfileData } from './user-sessions'

export const getSlugFromNextReq = (req: NextApiRequest) => getLocationSlug({ req })

export const ErrorResponse = t.union([
  t.type({
    code: t.string,
  }),
  t.type({
    code: t.literal('SESSION_EXPIRED'),
    session: Session,
  }),
  t.type({
    code: t.literal('TAKEAWAY_DISABLED'),
  }),
])
export type ErrorResponse = t.TypeOf<typeof ErrorResponse>

export const getSessionIdFromNextReq = (req: NextApiRequest) => {
  const cookies = parseCookies({ req })

  const sessionIdFromCookie = cookies.sessionId as UUID
  const sessionIdFromHeader = req.headers['session-id'] as UUID
  return { sessionId: sessionIdFromCookie ?? sessionIdFromHeader }
}

export const getServiceUrl = (config: Config) => {
  return `${config.STOREFRONT_SERVICE_HOST}:${config.STOREFRONT_SERVICE_PORT}`
}

export const getTempToken = (config: Config) => {
  return jwt.sign({}, `${config.STOREFRONT_SERVICE_TOKEN_SECRET}`, {
    expiresIn: '1m',
    audience: config.STOREFRONT_SERVICE_TOKEN_AUDIENCE,
    issuer: config.STOREFRONT_SERVICE_TOKEN_ISSUER,
  })
}

export const getReturnToBaseUrl = (request: IncomingMessage): URL | null => {
  const host = request.headers['host'] || ''
  if (!host.length) {
    return null
  }
  const socket = request.socket as TLSSocket
  const protocol = socket.encrypted ? 'https' : 'http'
  const url = new URL(protocol + '://' + host)
  return url
}

// Make a request to storefront service and receive the response. Must be used
// from the backend in storefront web, since storefront service is not accessible from the frontend.
// NOTE(christoffer) This is basically the same functionality as makeFetchRequest(), but with an,
// arguably, saner API. At the very least it's more flexible.
export const storefrontServiceRequest = async (args: {
  url: string
  method: 'GET' | 'POST' | 'DELETE'
  payload?: unknown
  headers?: HeadersInit
}) => {
  const { method, url, payload } = args
  const config = setupConfig()
  const serviceBase = getServiceUrl(config)
  const requestToken = getTempToken(config)
  const apiEndpoint = new URL(url, serviceBase)
  const body = args.payload ? JSON.stringify(payload) : undefined

  const headers: Headers = args.headers ? new Headers(args.headers) : new Headers()
  headers.set('Content-Type', 'application/json')
  headers.set('Authorization', `Bearer ${requestToken}`)
  if (!headers.has('x-correlation-id')) {
    headers.set('x-correlation-id', v4())
  }
  const response = await fetch(apiEndpoint, { method, body, headers })
  return response
}

// Make a GET request to storefront service
export const storefrontServiceGet = async (url: string, headers?: HeadersInit) => {
  return storefrontServiceRequest({ url, method: 'GET', headers })
}

// Make a POST request to storefront service
export const storefrontServicePost = async (
  url: string,
  payload: unknown,
  headers?: HeadersInit
) => {
  return storefrontServiceRequest({ url, method: 'POST', payload, headers })
}

// Make a DELETE request to storefront service
export const storefrontServiceDelete = async (url: string, headers?: HeadersInit) => {
  return storefrontServiceRequest({ url, method: 'DELETE', headers })
}

// Convenience function to extract the storefront session id and set the cid on
// a storefront service request. The cid is only set if the request is missing
// it. I.e. an existing cid is not overwritten.
export const buildHeaders = (cid?: string, req?: NextApiRequest) => {
  const headersInit: HeadersInit = {}

  const requestCid = req?.headers['x-correlation-id']
  if (!requestCid && cid) {
    headersInit['x-correlation-id'] = cid
  }
  const sessionHeaders = req ? getSessionIdFromNextReq(req) : { sessionId: undefined }
  if (sessionHeaders?.sessionId) {
    headersInit['session-id'] = sessionHeaders.sessionId
  }
  return headersInit
}

export const withUserSessionHeaders = (userSessionId: UUID, cid?: string) => {
  return { ...buildHeaders(cid), 'x-user-session-id': userSessionId }
}

const SessionExpired = t.type({
  code: t.literal('SESSION_EXPIRED'),
  session: Session,
})

export type ClientSideApiHandler<T> = (
  response: AxiosResponse<Record<string, unknown>>
) => Promise<T | undefined>

export const useClientSideApiHandler = <T>(
  decoder: t.Type<T, unknown>
): ClientSideApiHandler<T> => {
  const router = useRouter()
  return async (response: AxiosResponse<Record<string, unknown>>) => {
    if (response.status === 400) {
      const sessionExpiredDecoded = SessionExpired.decode(response.data)
      if (sessionExpiredDecoded._tag === 'Right') {
        const { session } = sessionExpiredDecoded.right
        const destination = `/session-expired?delivery=${session.delivery.kind}`
        router.push(destination)
        return
      }
    }
    if (response.status >= 400) {
      const errorResponse: { data?: { code?: string; message?: string } } = response
      const errorCode = errorResponse.data?.code ?? 'UNKNOWN'
      const errorMessage = errorResponse.data?.message
      throw new RequestError({ statusCode: response.status, errorCode, message: errorMessage })
    }
    const decoded = decoder.decode(response.data)
    if (decoded._tag === 'Left') {
      logger.error('Type validation report', reporter.report(decoded))
      throw new Error('API response: Validation failed')
    }
    return decoded.right
  }
}

/**
 * Wraps a getServerSideProps() function with error handling and default props.
 * Required for all pages.
 * Usage in pages:
 *
 *   export const getServerSideProps = withGlobalProps(async (context) => {
 *     // ... my getServerSideProps implementation
 *   })
 *
 */
export const withAppPageProps =
  <T>(
    getServerSideProps: GetSessionfulPageProps<T> | GetSessionlessPageProps<T>,
    options: { checkForSessionExpiration: boolean } = { checkForSessionExpiration: true }
  ) =>
  async (context: GetServerSidePropsContext) => {
    try {
      const pageProps = await getServerSideProps(context)

      if ('props' in pageProps) {
        // NOTE(christoffer): If the page returned props (and not redirect, etc), then enrich the
        // props with the global props required for the outermost (app-level) component.
        const config = setupConfig()
        const cookies = nookies.get(context, { path: '/' })
        const defaultEmail = cookies[EMAIL_COOKIE_PATH] ?? null
        const signInConfigs = {
          klarnaClientId: config.KLARNA_CLIENT_ID,
        }

        let userProfile: UserProfileData | null = null
        // NOTE(christoffer): Due to the nesting nature of this wrapper, the page's getProps() is called before this function.
        // In case the page needed access to the userProfile data, it might already have resolved it, and it would be wasteful
        // make another round trip to fetch the same data again for the global context. Therefore, we attempt to re-use any
        // userProfile data (semi-sloppily) based on the page props names.
        if ('userProfile' in pageProps.props && UserProfileData.is(pageProps.props.userProfile)) {
          userProfile = pageProps.props.userProfile
        } else {
          const slug = getLocationSlug(context)
          userProfile = await getUserProfile({ context, cid: v4(), slug })
        }

        const appPageProps: AppPageProps = {
          appProps: {
            defaultEmail,
            signInConfigs,
            userProfile,
          },
        }
        return {
          props: {
            ...appPageProps,
            ...pageProps.props,
          },
        }
      }

      return pageProps
    } catch (error) {
      // Note(martin): The checkForSessionExpiration check is mostly a safeguard to avoid infinite redirects
      // in case the session-expired page ever makes a request that throws a SessionExpiredError.
      if (options.checkForSessionExpiration && error instanceof SessionExpiredError) {
        const destination = `/session-expired?delivery=${error.session.delivery.kind}`
        return {
          redirect: { destination, permanent: false },
        }
      }
      if (error instanceof NotFoundError) {
        logger.warn(error.message, { stack: error.stack })
        return { notFound: true }
      }
      if (error instanceof SessionRequestError) {
        return {
          redirect: { destination: '/qr', permanent: false },
        }
      }
      throw new GetServerSidePropsError(error as Error)
    }
  }

// note(martin): This will be caught and console.logged by Next.js
// To avoid cluttering the GCP logs with multi line logs
// we log the stack as the message and skip all other fields
class GetServerSidePropsError extends Error {
  constructor(cause: Error) {
    super(cause.stack)
    // As it's only used for wrapping errors
    // we don't need to specify that it's an Error in the name
    this.name = 'GetServerSideProps'
  }
}

export const getSafeJsonBody = async (fetchResponse: Response) => {
  try {
    return await fetchResponse.json()
  } catch (error) {
    return {
      metaError: 'Failed to get JSON body from fetch response',
    }
  }
}
